<template>
  <QtyControls
    :value="count"
    v-bind="{ ...$attrs }"
    :min="min"
    :max="maxQuantity"
    :step="step"
    :show-step-count="false"
    :inverted="true"
    :variant="'primary'"
    :disabled="!stock"
    @input="requestChangeCount"
  >
    <template #empty>
      <slot name="empty-place-holder" />
    </template>
  </QtyControls>
</template>
<script setup lang="ts">
/**
 *
 * @description this one controls the amount of items to be added to the cart through the qty controls
 *
 * @case when the product min amount on cart is reached - toggle the remove from cart button
 */
defineComponent({
  name: 'ProductCardQtyControl',
});
const props = defineProps({
  sku: {
    type: String,
    required: true,
  },
  min: {
    type: Number,
    required: true,
  },
  max: {
    type: Number,
    required: true,
  },
  step: {
    type: Number,
    required: true,
  },
  stock: {
    type: Number,
    required: true,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { count, items, requestChangeCount, maxQuantity } = useQuantityControl(
  props.sku,
  props.max,
  props.min,
  props.stock,
);
</script>

<i18n>
{
  "en": {
    "addToCart": "Add to cart"
  }
}
</i18n>
