<template>
  <div class="CartItem relative md:gap-y-3">
    <div class="flex items-stretch gap-x-6 md:gap-x-8">
      <AppLink
        :to="isGift ? '' : `/${url}`"
        class="CartItem__image bg-white relative"
        :class="{ 'opacity-50': isInvalidItem }"
      >
        <NuxtImg
          v-if="image.src"
          :src="image.src"
          :alt="image.alt || name"
          class="rounded-[20px]"
          format="webp"
          width="200"
          height="200"
        />
      </AppLink>
      <div class="flex flex-col md:justify-center w-full">
        <div class="flex w-full" :class="{ 'mb-auto': isGift }">
          <AppLink :to="isGift ? '' : `/${url}`" class="justify-self-start">
            <span
              data-attr="cartItem__productName__txt"
              class="CartItem__name [ text-base-1 md:text-base capitalize font-display ]"
            >
              {{ name }}
            </span>
            <span v-if="!stock" class="block mt-1 text-sm lg:text-base text-gray-90">{{ $t('outOfStockLabel') }}</span>
            <span v-if="stock && stock < quantity" class="block mt-1 text-sm lg:text-base text-primary-500">{{
              $t('qtyUnavailableLabel')
            }}</span>
          </AppLink>

          <button
            v-if="updatable && !isGift"
            type="button"
            class="ml-auto [ flex items-center justify-center self-start ]"
            data-attr="cartItem__remove"
            @click.stop="removeItem"
          >
            <svg-icon-trash v-if="!isRemoving" :class="{ hidden: isInvalidItem }" width="24" height="24" />
            <Spinner v-else class="w-5 h-5 text-primary-1-100" />
          </button>
        </div>

        <!-- <span class="text-sm"> {{ options }}</span> -->

        <div class="w-full flex gap-x-2.5 mt-3" :class="{ 'opacity-50': isInvalidItem }">
          <p v-if="!updatable || isGift" class="text-sm">
            <span class="text-gray-700">Qty.</span>
            <span class="text-black font-medium">{{ quantity }}</span>
          </p>

          <template v-if="!isGift">
            <p class="font-medium text-sm md:text-base">
              <Money :value="price" />
            </p>
            <p v-if="oldPrice && oldPrice !== price" class="text-system-gray text-tiny md:text-base">
              <Money :value="oldPrice" class="line-through" />
            </p>
          </template>
        </div>
        <p v-if="isInvalidItem" class="flex gap-x-2 items-center md:mb-2">
          <svg-icon-error class="w-3 h-4 z-20 text-red-700" />
          <span class="text-red-700 text-base uppercase tracking-wider">{{ $t('outOfStockLabel') }}</span>
        </p>

        <button
          v-if="isInvalidItem"
          class="[ mt-4 lg:mt-3.5 py-2 ] [ h-12 w-full md:w-45 ] border border-red-700 rounded-xl flex justify-center items-center"
          @click.stop="removeItem"
        >
          <p v-if="!isRemoving" class="flex gap-x-3 justify-center items-center">
            <svg-icon-trash class="w-5 h-5" />
            <span class="uppercase text-base text-red-700 tracking-widest md:hidden">{{ $t('remove') }}</span>
            <span class="uppercase text-base text-red-700 tracking-widest hidden md:block">{{ $t('removeItem') }}</span>
          </p>

          <Spinner v-else class="w-5 h-5 text-primary-700" />
        </button>

        <CartItemQtyControl
          v-else-if="allowChangeQuantity && updatable && !isGift"
          class="[ mt-4 lg:mt-3.5 py-2 ] [ h-12 w-full md:w-45 ] border border-primary-2-06 rounded-xl"
          :sku="sku"
          :max="cartControl.max"
          :min="cartControl.min"
          :step="cartControl.step"
          :stock="stock"
        >
          <template #empty-place-holder>
            <Spinner class="w-6 h-6 mx-auto" />
          </template>
        </CartItemQtyControl>
      </div>
    </div>

    <!-- <div
      class="lg:ml-0 mt-4 md:col-span-2 lg:mt-0 row-start-3 lg:row-start-auto flex items-center w-full justify-between"
    >
      <div class="qty-control-input w-full relative [ flex items-center ] [ md:grid md:grid-cols-2 ] space-x-2 w-32">
        <div class="hidden space-x-5 md:justify-end">
          <p class="font-semibold text-primary-900">
            <Money :value="price" />
          </p>
          <p v-if="oldPrice && oldPrice !== price" class="text-xs line-through text-primary-900">
            <Money :value="oldPrice" />
          </p>
        </div>
      </div>
    </div> -->

    <CartItemGiftWrapping
      v-if="giftBoxOptionId || giftBoxMessage"
      :gift-box-message="giftBoxMessage"
      :item-id="id"
      :option-id="giftBoxOptionId"
      class="mt-7"
      horizontal
    />
  </div>
</template>

<script setup lang="ts">
import type { CartControl } from '@robustastudio/e-commerce/common';

defineComponent({
  name: 'CartItem',
});

const props = defineProps({
  id: {
    type: null,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  image: {
    type: Object,
    required: true,
  },
  quantity: {
    type: Number,
    required: true,
  },
  price: {
    type: Number,
    required: true,
  },
  oldPrice: {
    type: Number,
    default: undefined,
  },
  updatable: {
    type: Boolean,
    default: false,
  },
  stock: {
    type: Number,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  cartControl: {
    type: Object as PropType<CartControl>,
    required: false,
    default: () => ({}),
  },
  type: {
    type: String,
    required: false,
    default: 'SimpleProduct',
  },
  url: {
    type: String,
    required: false,
    default: '',
  },
  brand: {
    type: String,
    required: false,
    default: '',
  },
  configurationOptions: {
    type: Array as PropType<{ label: string; value: string }[]>,
    default: () => [],
  },
  isInvalidItem: {
    type: Boolean,
    default: true,
  },
  allowChangeQuantity: {
    type: Boolean,
    default: true,
  },
  giftBoxMessage: {
    type: String,
    default: '',
  },
  giftBoxOptionId: {
    type: [String, Number],
    default: '',
  },
  isGift: {
    type: Boolean,
    default: false,
  },
});

const { removeItem, isRemoving } = useRemoveCartItem(props.id);
const { updateItem } = useUpdateCartItem(props.id);
const { error } = useAlerts();
const { t: $t } = useI18n();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function onQuantityUpdate(value: number) {
  try {
    await updateItem(value);
  } catch (err) {
    if (/the requested qty is not available/i.test((err as Error).message)) {
      error($t('qtyUnavailable', [name]) as string);
      return;
    }

    if (
      /Product that you are trying to add is not available/i.test((err as Error).message) ||
      /Some of the products are out of stock/i.test((err as Error).message)
    ) {
      error($t('outOfStockError', [name]) as string);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const options = computed(() => props.configurationOptions.map(curr => `${curr.label} ${curr.value}`).join(' | '));
</script>

<style lang="postcss" scoped>
.CartItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  &__image {
    @apply flex-shrink-0 rounded-[20px];
    width: 147px;
    height: 147px;

    img {
      @apply object-cover w-full;
      aspect-ratio: 1 / 1;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@screen md {
  .CartItem {
    grid-template-columns: 1fr;
    column-gap: 15px;
  }
}

:deep(.QuantityInput) {
  width: auto;
}

.qty-control-input::after {
  @apply absolute bottom-0 left-0 bg-primary-200;
  content: ' ';
  width: 100%;
}
</style>

<i18n>
{
  "en": {
    "qtyUnavailable": "The requested quantity of {0} is unavailable, please choose a lower quantity",
    "outOfStockError": "{0} is out of stock",
    "outOfStockLabel": "Out of Stock",
    "qtyUnavailableLabel": "Quantity Unavailable",
    "remove": "Remove",
    "removeItem": "Remove item"
  },
  "ar": {
    "qtyUnavailable": "الكمية المطلوبة من {0} غير متوفرة، من فضلك قم باختيار كمية أقل",
    "outOfStockError": "{0} غير متوفر",
    "outOfStockLabel": "نفذت الكمية",
    "qtyUnavailableLabel": "الكمية غير متوفرة",
    "remove": "إزاله",
    "removeItem": "إزاله المنتج"
  }
}
</i18n>
